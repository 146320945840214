html, body, #root {
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 100px #F2F2F2;
  box-shadow: inset 0 0 100px #F2F2F2;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 100px #BDBDBD;
  box-shadow: inset 0 0 100px #BDBDBD;
}

:focus {
  outline: none !important;
}

.MuiDialog-paperWidthSm {
  max-width: none !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding-top: 0.0px !important;
  padding-bottom: 0.0px !important;
}

.MuiOutlinedInput-multiline {
  padding-top: 14.5px !important;
  padding-bottom: 14.5px !important;
}

/* .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-bottom: 0.66px;
  padding-top: 0.66px;
} */